import React from 'react';
import { BoltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Relief from back pain, depression, and more',
    description:
      'Bowen Therapy is an integrative therapy that targets the entire body — including the muscles, joints, ligaments, fascia and skin — to help release tension and restore balance.',
    icon: GlobeAltIcon,
  },
  {
    name: 'No more pills or needles',
    description:
      'Bowen Technique is a non-invasive way to reduce stress and anxiety levels by releasing built up energy. It also helps you sleep better at night!',
    icon: ScaleIcon,
  },
  {
    name: 'Free from pain with Bowen Therapy',
    description:
      'Discover how Bowen Technique can help you heal from chronic back pain and other chronic conditions like plantar fasciitis and carpal tunnel syndrome.',
    icon: BoltIcon,
  },
];

export default function Features() {
  return (
    <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
      <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <div className="relative">
          <h2 className="text-center text-3xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Find relief with Pure Bowen Therapy
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
            With holistic Bowen Technique. There's no need for any needles or
            pills, so you can be sure that your sessions will be safe and
            gentle.
          </p>
        </div>
        <dl className="mt-20  grid grid-cols-1 gap-16 lg:grid lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
